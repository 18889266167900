import { Types } from "../constants/student-types";

const initialState = {
  studentDataList: [],
  sessionData: [],
  studentInfo: {},
  studentInfoOther: {},
  studentDeleteMessage:""
};
export default function studentReducers(state = initialState, action) {
  switch (action.type) {
    case Types.STUDENTS_FETCHED_SUCCESSFULLY:
      return {
        ...state,
        studentDataList: action.payload,
        statuscode: action.statuscode,
      };
    case Types.STUDENTS_UPDATED_SUCCESSFULLY:
      return {
        ...state,
        searchData: action.payload,
        statuscode: action.statuscode,
      };
    case Types.STUDENTS_UPDATED_BASIC_DETAILS_SUCCESSFULLY:
      return {
        ...state,
        searchBasicData: action.payload,
        statuscode: action.statuscode,
      };
    case Types.RESET:
      return { ...state, studentDataList: [] };
    case Types.STUDENT_INFORMATION_FETCHED_SUCCESSFULLY:
      return {
        ...state,
        statuscode: action.payload.status,
        studentInfo: action.payload,
      };
    case Types.STUDENT_INFORMATION_FETCHED_SUCCESSFULLY_OTHER:
      return {
        ...state,
        statuscode: action.payload.status,
        studentInfoOther: action.payload,
      };
      case Types.SESSION_SEARCHED_SUCCESSFULLY:
        return {
          ...state,
          sessionData: action.payload,
          statuscode: action.statuscode,
        };
    case Types.DELETE_DOCUMENT_SUCCESSFULLY:
      return {
        ...state,
        statuscode: action.payload.status,
        studentDeleteMessage: action.payload.message,
      };

    default:
      return state;
  }
}
