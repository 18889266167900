export const Types ={
    PROFILE_FETCHED_SUCCESSFULLY: 'PROFILE_FETCHED_SUCCESSFULLY',
    ADMISSION_SEARCHED_SUCCESSFULLY:"ADMISSION_SEARCHED_SUCCESSFULLY",
    PROFILE_SEARCHED_SUCCESSFULLY:"PROFILE_SEARCHED_SUCCESSFULLY",
    PROFILE_UPDATED_SUCCESSFULLY:"PROFILE_UPDATED_SUCCESSFULLY",
    SESSION_SEARCHED_SUCCESSFULLY:"SESSION_SEARCHED_SUCCESSFULLY",
    TYPE_SEARCHED_SUCCESSFULLY: "TYPE_SEARCHED_SUCCESSFULLY",
    STUDENTS_FETCHED_SUCCESSFULLY:"STUDENTS_FETCHED_SUCCESSFULLY",
    ERROR_OCCURED:"ERROR_OCCURED",
    GROUP_SEARCHED_SUCCESSFULLY : "GROUP_SEARCHED_SUCCESSFULLY",
    EXPORT_SUMMARY:"EXPORT_SUMMARY",
    EXPORT_STUDENT:"EXPORT_STUDENT",
    EXPORT_SUBJECT: "EXPORT_SUBJECT",
    COMPUTE_AVERAGE: "COMPUTE_AVERAGE",
    DELETE_GROUP:"DELETE_GROUP",
    RESET:"RESET",
    DASHBOARD_DATA_FETCHED_SUCCESSFULLY: "DASHBOARD_DATA_FETCHED_SUCCESSFULLY",
    GROUP_DATA_FETCHED_SUCCESSFULLY: "GROUP_DATA_FETCHED_SUCCESSFULLY",
    GROUP_DATA_UPDATED_SUCCESSFULLY: "GROUP_DATA_UPDATED_SUCCESSFULLY",
    SESSION_CREATED_SUCCESSFULLY: "SESSION_CREATED_SUCCESSFULLY",
    STUDENT_PROMOTED_SUCCESSFULLY: "STUDENT_PROMOTED_SUCCESSFULLY",
    NEW_STUDENTS_DATA_FETCHED_SUCCESSFULLY: "NEW_STUDENTS_DATA_FETCHED_SUCCESSFULLY",
    USERS_FETCHED_SUCCESSFULLY: "USERS_FETCHED_SUCCESSFULLY",
    ADD_USER: "ADD_USER",
    DELETE_USER: "DELETE_USER",
    SUBJECT_DATA_FETCHED_SUCCESSFULLY: "SUBJECT_DATA_FETCHED_SUCCESSFULLY",
    TYPES_DATA_FETCHED_SUCCESSFULLY: "TYPES_DATA_FETCHED_SUCCESSFULLY"

}
