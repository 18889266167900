import * as React from "react";
import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";

const Search = React.lazy(() => import("../components/Search/search-user"));
const Login = React.lazy(() => import("../components/User/login"));
const Profile = React.lazy(() => import("../components/User/profile"));
const Sidebar = React.lazy(() => import("../container/sidebar"));
const Export = React.lazy(() => import("../components/Export/export"));
const EditStudent = React.lazy(() => import("../components/UpdateStudent/edit-student"));
const EditGroup = React.lazy(() => import("../components/UpdateGroup/edit-group"));
const Dashboard = React.lazy(() => import("../components/Dashboard/dashboard"));
const Session = React.lazy(() => import("../components/Session/sessions"));
const Admissions = React.lazy(() => import("../components/Admissions/admissions"));
const Users = React.lazy(() => import("../components/User/list"));

const Routers = () => {
  return [
    {
      path: "",
      element: <Layout component={<Login />} />,
    },
    {
      path: "search",
      element: (
        <PrivateRoute
          component={<Search />}
        />
      ),
    },
    {
      path: "profile",
      element: (
        <PrivateRoute
          component={<Profile />}
        />
      ),
    },
    {
      path: "upload",
      element: (
        <PrivateRoute
          component={<Export />}
        />
      ),
    },
    {
      path: "sessions",
      element: (
        <PrivateRoute
          component={<Session />}
        />
      ),
    },
    {
      path: "admissions",
      element: (
        <PrivateRoute
          component={<Admissions />}
        />
      ),
    },
    {
      path: "users",
      element: (
        <PrivateRoute
          component={<Users/>}
        />
      ),
    },
  
    {
      path: "student/edit/:id",
      element: (
        <PrivateRoute
          component={<EditStudent />}
        />
      ),
    },
    {
      path: "dashboard",
      element: (
        <PrivateRoute
          component={<Dashboard />}
        />
      ),
    },
    {
      path: "group/edit/:id",
      element: (
        <PrivateRoute
          component={<EditGroup />}
        />
      ),
    },
  ];
};

function Layout(props) {
  return (
    <div>
      {props.component}
      <Outlet />
    </div>
  );
}
function PrivateRoute(props) {
  const [LeftWidth, setLeftWidth] = React.useState(8);
  function getLeftWidth(id) {
    sessionStorage.setItem('size',id? 30 :8)
    setLeftWidth(id? 30 :8);
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Sidebar getLeftWidth={getLeftWidth} width={LeftWidth} />
        </Grid>
        <Grid item  sx={{ mt: 8 ,ml:LeftWidth , width:'100%'}}>
          {props.component}
        </Grid>
      </Grid>

      <Outlet />
    </div>
  );
}

export default Routers;
